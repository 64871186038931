import { Suspense, lazy } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import Home from './components/pages/Home';
import Login from './components/pages/Login';
import App from "./App";
import NotFound from "./components/pages/errors/NotFound";
import AccessDenied from "./components/pages/errors/AccessDenied";
import InternalError from "./components/pages/errors/InternalError";
import SolidarityPartnersPage from "./components/pages/partners/SolidarityPartners";
import Logout from "./components/pages/Logout";

import NetworkError from "./components/pages/errors/NetworkError";
import PartnerFormPage from "./components/pages/partners/PartnerForm";
import PartnerViewPage from "./components/pages/partners/PartnerView";
import PartnerListPage from "./components/pages/partners/PartnerList";

import MaintenanceFormPage from "./components/pages/maintenance/MaintenanceForm";

import ScoreAgrolendModal from "./components/pages/referrals/ScoreAgrolendModal";
import Loading from "./components/ions/Loading";
import BiroListModal from "./components/pages/referrals/BiroListModal";
import BiroVariablesModal from "./components/pages/referrals/BiroVariablesModal";
import BiroDocumentModal from "./components/pages/referrals/BiroDocumentModal";
import ReferralDetailModal from "./components/pages/referrals/ReferralDetailModal";

import ReferralPartnerAnalysisListPage from './components/pages/referrals/PartnerAnalysisList';
import CreditLimitAnalysisForm from "./components/pages/referrals/CreditLimitAnalysisForm";
import ReferralPartnerAnalyzedListPage from './components/pages/referrals/PartnerAnalyzedList';
import RequireAuth from "./services/Auth/RequireAuth";

import FarmerOrderListPage from './components/pages/referrals/FarmerOrderList';

import BatchAnalysisListPage from "./components/pages/referrals/BatchAnalysisList";
import BatchAnalysisFormPage from "./components/pages/referrals/BatchAnalysisForm";
import BatchAnalysisView from "./components/pages/referrals/BatchAnalysisView";

import CommercialPage from "./components/pages/commercial/CommercialPage";
import CommercialFormPage from "./components/pages/commercial/CommercialFormPage";

import FeatureFlagReferral from './components/pages/configuration/FeatureFlagReferralListPage';
import FeatureFlagReferralView from "./components/pages/configuration/FeatureFlagReferralViewPage";
import ReasonPageList from "./components/pages/referrals/ReasonPageList";
import ReasonFormModal from "./components/pages/referrals/ReasonFormModal";
import ReferralConfiguration from "./components/pages/referrals/ReferralConfiguration";
import LoanOrderAnalysisForm from "./components/pages/referrals/LoanOrderAnalysisForm";
import LoanOrderAnalysisListPage from "./components/pages/referrals/LoanOrderAnalysisList";

import NotificationPageList from "./components/pages/communication/NotificationPageList";
import NotificationCalendarPage from "./components/pages/communication/NotificationCalendar";
import NotificationFormPage from "./components/pages/communication/NotificationForm";
import BatchNotificationForm from "./components/pages/communication/BatchNotifcationForm";
import ContractDisbursementList from "./components/pages/financial/ContractDisbursementList";

const Cities = lazy(() => import('./components/pages/geo-location/Cities'));
const Provinces = lazy(() => import('./components/pages/geo-location/Provinces'));


const AppRoutes = () => {

    const ADMIN = 'ADMIN';
    const OPERATOR = 'OPERATOR';
    const LEGAL = 'LEGAL';
    const CREDIT_ANALYST = 'CREDIT_ANALYST';
    const CREDIT_MANAGER = 'CREDIT_MANAGER';

    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<App />} errorElement={<InternalError />}>
                    <Route path="home" element={<RequireAuth roles={[ADMIN, OPERATOR, LEGAL, CREDIT_ANALYST, CREDIT_MANAGER]}><Home /></RequireAuth>} />
                    <Route path="partners" element={<PartnerListPage />} />
                    <Route path="partners/view/:id" element={<PartnerViewPage />} />
                    <Route path="partners/form" element={<PartnerFormPage />} />
                    <Route path="solidarity-partners" element={<SolidarityPartnersPage />} />

                    <Route path="referrals/partner-analysis/:analysisType/:referralId" element={
                        <RequireAuth roles={[ADMIN, CREDIT_MANAGER, CREDIT_ANALYST]}>
                            <CreditLimitAnalysisForm />
                        </RequireAuth>
                    } />

                    <Route path="referrals/partner-analysis/:page/:pageSize/:field/:order/:filter?"
                        element={<RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}><ReferralPartnerAnalysisListPage /></RequireAuth>}>
                        <Route
                            path="agrolend-score/:referralId"
                            element={<ScoreAgrolendModal />}
                        />
                        <Route
                            path="biro-list/:referralId/:partnerId"
                            element={<BiroListModal />}
                        />
                        <Route
                            path="biro-variables/:referralId/:providerName"
                            element={<BiroVariablesModal />}
                        />
                        <Route
                            path="biro-document/:referralId/:providerName"
                            element={<BiroDocumentModal />}
                        />
                        <Route
                            path="referral-detail/:referralId"
                            element={<ReferralDetailModal />}
                        />
                    </Route>

                    <Route path="referrals/loan-order-analysis/:analysisType/:referralId" element={
                        <RequireAuth roles={[ADMIN, CREDIT_MANAGER, CREDIT_ANALYST]}>
                            <LoanOrderAnalysisForm />
                        </RequireAuth>
                    } />

                    <Route path="referrals/loan-order-analysis/:page/:pageSize/:field/:order/:filter?" element={
                        <RequireAuth roles={[ADMIN, CREDIT_MANAGER, CREDIT_ANALYST]}>
                            <LoanOrderAnalysisListPage />
                        </RequireAuth>
                    } />

                    <Route path="referrals/farmer-analysis/:page/:pageSize/:field/:order/:filter?"
                        element={<RequireAuth roles={[ADMIN, CREDIT_MANAGER]}><FarmerOrderListPage /></RequireAuth>}>
                        <Route
                            path="agrolend-score/:referralId"
                            element={<ScoreAgrolendModal />}
                        />
                        <Route
                            path="biro-list/:referralId/:partnerId"
                            element={<BiroListModal />}
                        />
                        <Route
                            path="biro-variables/:referralId/:providerName"
                            element={<BiroVariablesModal />}
                        />
                        <Route
                            path="biro-document/:referralId/:providerName"
                            element={<BiroDocumentModal />}
                        />
                        <Route
                            path="referral-detail/:referralId"
                            element={<ReferralDetailModal />}
                        />
                    </Route>

                    <Route path="referrals/partner-analyzed/:page/:pageSize/:field/:order/:filter?"
                        element={<RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}><ReferralPartnerAnalyzedListPage /></RequireAuth>}>
                        <Route
                            path="agrolend-score/:referralId"
                            element={<ScoreAgrolendModal />}
                        />
                        <Route
                            path="biro-list/:referralId/:partnerId"
                            element={<BiroListModal />}
                        />
                        <Route
                            path="biro-variables/:referralId/:providerName"
                            element={<BiroVariablesModal />}
                        />
                        <Route
                            path="biro-document/:referralId/:providerName"
                            element={<BiroDocumentModal />}
                        />
                        <Route
                            path="referral-detail/:referralId"
                            element={<ReferralDetailModal />}
                        />
                    </Route>

                    <Route path="analysis/batch-analysis/:page/:pageSize/:field/:order/:filter?"
                        element={
                            <RequireAuth roles={[ADMIN, OPERATOR]}>
                                <BatchAnalysisListPage />
                            </RequireAuth>
                        } />

                    <Route path="analysis/batch-analysis/form" element={
                        <RequireAuth roles={[ADMIN, OPERATOR]}>
                            <BatchAnalysisFormPage />
                        </RequireAuth>
                    } />

                    <Route path="analysis/batch-analysis/view/:id" element={
                        <RequireAuth roles={[ADMIN, OPERATOR]}>
                            <BatchAnalysisView />
                        </RequireAuth>
                    } />

                    <Route path="cities" element={
                        <RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}>
                            <Suspense fallback={<Loading />}>
                                <Cities />
                            </Suspense>
                        </RequireAuth>
                    } />

                    <Route path="provinces" element={
                        <RequireAuth roles={[ADMIN, CREDIT_ANALYST, CREDIT_MANAGER]}>
                            <Suspense fallback={<Loading />}>
                                <Provinces />
                            </Suspense>
                        </RequireAuth>}
                    />

                    <Route path="commercial/:page/:pageSize/:field/:order/:filter?" element={
                        <RequireAuth roles={[ADMIN, OPERATOR]}>
                            <CommercialPage />
                        </RequireAuth>
                    } />

                    <Route
                        path="commercial-association"
                        element={
                            <RequireAuth roles={[ADMIN, OPERATOR]}>
                                <CommercialFormPage />
                            </RequireAuth>
                        }
                    />

                    <Route path="referrals/reasons/:page/:pageSize/:field/:order/:filter?"
                        element={
                            <RequireAuth roles={[ADMIN, CREDIT_MANAGER]}>
                                <ReasonPageList />
                            </RequireAuth>
                        }
                    >
                        <Route
                            path="form"
                            element={<ReasonFormModal />}
                        />
                    </Route>

                    <Route path="referrals/configuration"
                        element={
                            <RequireAuth roles={[ADMIN, CREDIT_MANAGER]}>
                                <ReferralConfiguration />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="feature-flags/referral/:page/:pageSize/:field/:order/:filter?"
                        element={
                            <RequireAuth roles={[ADMIN]}>
                                <FeatureFlagReferral />
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="feature-flags/referral/view/:id"
                        element={
                            <RequireAuth roles={[ADMIN]}>
                                <FeatureFlagReferralView />
                            </RequireAuth>
                        }
                    />

                    <Route path="communication/notification/:page/:pageSize/:field/:order/:filter?"
                        element={
                            <RequireAuth roles={[ADMIN, OPERATOR]}>
                                <NotificationPageList />
                            </RequireAuth>
                        }
                    />

                    <Route path="communication/calendar/:scheduledType/:year/:month"
                        element={<RequireAuth roles={[ADMIN]}>
                            <NotificationCalendarPage />
                        </RequireAuth>}
                    />

                    <Route path="communication/form/:id"
                        element={<RequireAuth roles={[ADMIN]}>
                            <NotificationFormPage />
                        </RequireAuth>}
                    />

                    <Route path="communication/batch-form/:scheduledType/:targetDate"
                        element={<RequireAuth roles={[ADMIN]}>
                            <BatchNotificationForm />
                        </RequireAuth>}
                    />

                    <Route
                        path="financial/disbursement-list"
                        element={
                            <RequireAuth roles={[ADMIN]}>
                                <ContractDisbursementList />
                            </RequireAuth>
                        }
                    />

                    <Route path="maintenance" element={<RequireAuth roles={[ADMIN]}><MaintenanceFormPage /></RequireAuth>} />
                    <Route path="/denied" element={<AccessDenied />} />
                    <Route path="/error" element={<InternalError />} />
                    <Route path="/network-error" element={<NetworkError />} />
                    <Route path="/logout" element={<Logout />} />
                </Route>
                <Route path="*" element={<NotFound />} />
                <Route path="/login" element={<Login />} />

            </Routes>
        </HashRouter >
    );
};

export default AppRoutes;