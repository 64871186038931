import { FC, useEffect, useState } from "react";
import ATable, { ATableHeader } from "../../organisms/ATable";

const BASE_URL = process.env.REACT_APP_CONTRACT_REGISTER_API_URL;
const WAIT_PROCESSING_STATUS = 'WAIT_PROCESSING';

type DisbursementProps = {
    id: number;
    cprf: string;
    status: string;
    farmerMame: string;
    value: number;
    valueFormatted: string;
};

type DisbursementDataTableProps = {
    headers: ATableHeader[];
    token: string;
    selectedDate: string;
    onDataLoaded: (hasPending: boolean, dataForm: string) => void;
};

const DisbursementDataTable: FC<DisbursementDataTableProps> = ({ headers, token, selectedDate, onDataLoaded }) => {
    const [data, setData] = useState<DisbursementProps[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setData([]);

            try {
                const response = await fetch(`${BASE_URL}/maintenance/disbursement?date=${selectedDate}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.ok) {
                    const dataList: DisbursementProps[] = await response.json();
                    const uniqueData = Array.from(new Map(dataList.map((item: DisbursementProps) => [item.id, item])).values());
                    const hasPending = uniqueData.some((row: DisbursementProps) => row.status === WAIT_PROCESSING_STATUS);
                    setData(uniqueData);
                    onDataLoaded(hasPending, new Date(selectedDate).toISOString());
                }
                else if (response.status === 404) {
                    setErrorMessage('Nenhum registro encontrado para esta data');
                    setData([]);
                    onDataLoaded(false, new Date(selectedDate).toISOString());
                } else {
                    setErrorMessage('Erro ao buscar dados. status: ' + response.status);
                    setData([]);
                    onDataLoaded(false, new Date(selectedDate).toISOString());
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (selectedDate) fetchData();
    }, [selectedDate, token, onDataLoaded]);

    const renderRows = (data: DisbursementProps[]) =>
        data.map((row, index) => (
            <tr key={row.id} className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2" align="center">{row.cprf}</td>
                <td className="py-2 px-2" align="center">{row.status}</td>
                <td className="py-2 px-2" align="center">{row.farmerMame}</td>
                <td className="py-2 px-2" align="center">{row.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
            </tr>
        ));

    let content;

    if (loading) {
        content = <div className="text-center py-5">🔄 Carregando dados...</div>;
    } else if (data.length === 0) {
        content = <div className="text-center py-5">{errorMessage}</div>;
    } else {
        content = (
            <ATable
                headers={headers}
                fieldSorted="id"
                orderSorted="ASC"
                onSort={() => { }}
                autoResize={false}
                marginTop={0}
            >
                {renderRows(data)}
            </ATable>
        );
    }

    return content;
};

export default DisbursementDataTable;