import { FC, useState } from "react";

type DateInputProps = {
    label: string;
    onChange: (e: any) => void;
}

const DateInput: FC<DateInputProps> = ({ label, onChange }) => {
    const [date, setDate] = useState<string>("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
        onChange(event.target.value);
    };

    return (
        <div>
            <label htmlFor="date">{label}</label>
            <input
                type="date"
                id="date"
                value={date}
                onChange={handleChange}
                className="border p-2 rounded"
            />
        </div>
    );
};

export default DateInput;