import useFetch from "../../../hooks/useFetch";
import ToggleSwitch from "../../atoms/ToogleSwitch";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ReprocessForm from "./ReprocessForm";
import { Auth } from "aws-amplify";
import AAlert from "../../molecules/AAlert";

const BASE_URL = process.env.REACT_APP_CONTRACT_REGISTER_API_URL;
const DISBURSEMENT_ACTIVE_PROPERTY = 'dimensa_disbursement_register_enabled';
const SETTLEMENT_ACTIVE_PROPERTY = 'dimensa_settle_register_enabled';

type BusinessProperties = {
    key: string;
    value: boolean;
};

const ContractDisbursementList = () => {

    const navigate = useNavigate();

    const [activeDisbursement, setActiveDisbursement] = useState(false);
    const [activeSettlement, setActiveSettlement] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: '',
        visible: false,
    });

    const token = useRef('');

    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getIdToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                fetchData(tkn);
            })
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    const fetchData = async (token: string) => {
        try {
            const [responseDisbursement, responseSettlement] = await Promise.all([
                apiGet(`${BASE_URL}/maintenance/variables/value/${DISBURSEMENT_ACTIVE_PROPERTY}`, token),
                apiGet(`${BASE_URL}/maintenance/variables/value/${SETTLEMENT_ACTIVE_PROPERTY}`, token)
            ]);

            if (responseDisbursement && responseSettlement) {
                const respDisb: BusinessProperties = await responseDisbursement.json();
                const respSettle: BusinessProperties = await responseSettlement.json();
                setActiveDisbursement(respDisb.value);
                setActiveSettlement(respSettle.value);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const apiGet = async (url: string, token: string) => {
        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Network response was not ok');
            return response;
        } catch (error) {
            navigate('/network-error');
            console.error('Error fetching data:', error);
        }
    };

    const handleToggle = async (type: string, status: boolean) => {
        if (![DISBURSEMENT_ACTIVE_PROPERTY, SETTLEMENT_ACTIVE_PROPERTY].includes(type)) {
            throw new Error('Invalid type');
        }
        const url = `${BASE_URL}/maintenance/variables/${type}/${status}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token.current}`
                },
                mode: 'cors'
            });

            if (!response.ok) throw new Error('Network response was not ok');

            showAlert('Ação realizada com sucesso!', 'success');
            if (type === DISBURSEMENT_ACTIVE_PROPERTY)
                setActiveDisbursement(status);
            else if (type === SETTLEMENT_ACTIVE_PROPERTY)
                setActiveSettlement(status);

        } catch (error) {
            showAlert('Erro ao salvar a propriedade', 'error');
            console.error('Error:', error);
        }
    };

    const showAlert = (message: string, type: 'success' | 'error' | 'warning' | 'info') => {
        setAlert({ message, type, visible: true });
        setTimeout(() => setAlert({ ...alert, visible: false }), 3000);
    };

    const closeAlert = () => {
        setAlert({ ...alert, visible: false });
    };

    return (
        isLoading ? <div>Loading...</div> :
            <div className='px-4'>
                {alert.visible && (
                    <AAlert message={alert.message} type={alert.type as any} onClose={closeAlert} />
                )}
                <div className="grid grid-cols-2 gap-2">
                    <div className="sticky top-0 z-10">
                        <h1 className="inline-block text-2xl text-teal-500 font-medium tracking-tight pt-2 pb-3">
                            Ativar/Desativar API de Desembolso
                        </h1>
                    </div>
                </div>
                <div className="panel-header">
                    <h3>{activeDisbursement ? 'API da Dimensa Ativada' : 'API da Dimensa Desativada'}</h3>
                    <ToggleSwitch
                        checked={activeDisbursement}
                        onChange={() => handleToggle(DISBURSEMENT_ACTIVE_PROPERTY, !activeDisbursement)}
                    />
                </div>

                <div className="grid grid-cols-2 gap-2">
                    <div className="sticky top-0 z-10">
                        <h1 className="inline-block text-2xl text-teal-500 font-medium tracking-tight pt-2 pb-3">
                            Ativar/Desativar API de Liquidação
                        </h1>
                    </div>
                </div>
                <div className="panel-header">
                    <h3>{activeSettlement ? 'API da Dimensa Ativada' : 'API da Dimensa Desativada'}</h3>
                    <ToggleSwitch
                        checked={activeSettlement}
                        onChange={() => handleToggle(SETTLEMENT_ACTIVE_PROPERTY, !activeSettlement)}
                    />
                </div>

                <div className="grid grid-cols-2 gap-2">
                    <div className="sticky top-0 z-10">
                        <h1 className="inline-block text-2xl text-teal-500 font-medium tracking-tight pt-2 pb-3">
                            Formulário de Reprocessamento
                        </h1>
                    </div>
                </div>
                <ReprocessForm token={token.current} />
            </div>
    );
};

export default ContractDisbursementList;
