import { FC, useCallback, useEffect, useState } from "react";
import { FaPoo } from "react-icons/fa6";

type HeaderTable = {
    label: string;
    field: string;
    required: boolean;
};

interface CopyPasteTableProps {

    fixedHeaders: HeaderTable[];
}

const CopyPasteTable: FC<CopyPasteTableProps> = ({ fixedHeaders = [] }) => {

    const [tableData, setTableData] = useState<string[][]>([]);
    const [draggingColumn, setDraggingColumn] = useState<number | null>(null);
    const [headers, setHeaders] = useState(fixedHeaders);

    const maxColumns = Math.max(headers.length, ...tableData.map(row => row.length));
    const tableHeight = "calc(100vh - 410px)";

    useEffect(() => {
        const handleEvent = () => {
            window.dispatchEvent(new CustomEvent("CopyPasteTable:jsonGenerated", { detail: generateJson() }));
        };

        window.addEventListener("CopyPasteTable:generateJson", handleEvent);
        window.addEventListener("CopyPasteTable:clearData", () => setTableData([]));

        return () => {
            window.removeEventListener("CopyPasteTable:generateJson", handleEvent);
            window.removeEventListener("CopyPasteTable:clearData", () => setTableData([]));
        };

    }, []);

    const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const pastedText = event.clipboardData.getData("text");
        const rows = pastedText.split("\n").map(row => row.split("\t"));

        const filteredRows = rows.filter(row => row.some(cell => cell.trim() !== ""));

        setTableData(filteredRows);
    }, []);

    const handleDragStart = (index: number) => {
        setDraggingColumn(index);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
        event.preventDefault();
        if (draggingColumn !== null && draggingColumn !== index) {
            const newHeaders = [...headers];
            const [movedHeader] = newHeaders.splice(draggingColumn, 1);
            newHeaders.splice(index, 0, movedHeader);
            setHeaders(newHeaders);
            setDraggingColumn(index);
        }
    };

    const handleDragEnd = () => {
        setDraggingColumn(null);
    };

    const removeColumn = (colIndex: number) => {
        setTableData(prevData => prevData.map(row => row.filter((_, i) => i !== colIndex)));
    };

    const removeRow = (rowIndex: number) => {
        setTableData(prevData => prevData.filter((_, i) => i !== rowIndex));
    };

    const generateJson = () => {
        return tableData.map(row =>
            headers.reduce((acc, header, colIndex) => {
                acc[header.field] = row[colIndex] || "";
                return acc;
            }, {} as Record<string, string>),
        );
    };

    return (
        <>
            {tableData.length == 0 && (
                <textarea
                    className="w-full p-2 border border-gray-300 rounded"
                    rows={5}
                    placeholder="Cole os dados do Excel aqui..."
                    onPaste={handlePaste}
                />
            )}

            {tableData.length > 0 && (
                <div className="overflow-x-auto w-full">
                    <div className="min-w-max">

                        <div className="flex sticky top-0 p-2 z-10 bg-white shadow-md">
                            {Array.from({ length: maxColumns }).map((_, colIndex) => (
                                <div
                                    key={colIndex}
                                    className={`flex-1 text-center font-bold p-2 relative cursor-move min-w-[150px] transition-all ${draggingColumn === colIndex ? 'bg-blue-300' : ''}`}
                                    draggable
                                    onDragStart={() => handleDragStart(colIndex)}
                                    onDragOver={(event) => handleDragOver(event, colIndex)}
                                    onDragEnd={handleDragEnd}
                                >
                                    {headers[colIndex] ? (
                                        <div className={`bg-blue-500 text-white p-2 rounded ${headers[colIndex].required ? 'font-bold' : 'font-normal'}`}>
                                            {headers[colIndex].label || "..."}
                                        </div>
                                    ) : (
                                        <div className="text-white p-2 rounded">...</div>
                                    )}
                                    <div className="flex justify-center mt-2">
                                        <button
                                            className="text-red-500 text-lg"
                                            onClick={() => removeColumn(colIndex)}
                                        >
                                            <FaPoo />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        < div className="overflow-y-auto " style={{ maxHeight: tableHeight }}>
                            {tableData.map((row, rowIndex) => (
                                <div key={rowIndex} className="flex items-center">
                                    {Array.from({ length: maxColumns }).map((_, colIndex) => (
                                        <div key={colIndex} className="flex-1 text-center p-2 border min-w-[150px]">
                                            {row[colIndex] || "-"}
                                        </div>
                                    ))}
                                    <button
                                        className="text-red-500 ml-2 text-lg"
                                        onClick={() => removeRow(rowIndex)}
                                    >
                                        <FaPoo />
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="p-4 bg-gray-100 text-center font-bold">
                            Linhas: {tableData.length} | Colunas: {maxColumns}
                        </div>
                    </div>
                </div>
            )}
        </>
    );

};

export default CopyPasteTable;