import React, { useState, useEffect } from "react";
import PageForm from "../../templates/PageForm";
import { useNavigate, useParams } from "react-router-dom";
import TextEdit from "../../molecules/TextEdit";
import { FaWhatsapp } from "react-icons/fa6";
import { useSignal } from "@preact/signals-react";
import { FaMailBulk } from "react-icons/fa";
import CopyPasteTable from "../../organisms/CopyPasteTable";
import SelectFilter from "../../organisms/SelectFilter";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { ErrorField } from '../../../types/ErrorField';
import { getErrorMessage, hasErrorMessage, removeErrorField } from '../../../utils/formErrorUtils';

const templateWpp = {
    description: "Mensagem de cobrança de duplicata a vencer em 30 dias",
    subject: "",
    from: "",
    replyTo: "",
    showCcField: false,
    showBccField: false,
    variables: [{
        label: "Vencimento",
        field: "vencimento",
        value: "",
        required: false,
    }, {
        label: "Número do título",
        field: "parcela",
        value: "",
        required: false,
    }, {
        label: "Valor",
        field: "valor",
        value: "",
        required: true,
    }, {
        label: "Link do boleto",
        field: "invoiceUrl",
        value: "",
        required: true,
    }],
    batchHeaders: [{ label: "Operação", field: "operation", required: true },
    { label: "Nome do contato", field: "contactName", required: true },
    { label: "Telefone", field: "phoneNumber", required: true },
    { label: "Vencimento", field: "dueDate", required: false },
    { label: "Número do título", field: "duplicate", required: false },
    { label: "Valor", field: "amount", required: true },
    { label: "Link do boleto", field: "invoiceUrl", required: true },
    ],
    sendOption: ["immediate", "later"],
};

const templateEmail = {
    description: "E-mail de cobrança de duplicata a vencer em 30 dias",
    subject: "AGROLEND - DUPLICATA - {{CREDIT_NOTE}}",
    from: "jean.dobre@agrolend.agr.br",
    replyTo: "jean.dobre@agrolend.agr.br",
    showCcField: true,
    showBccField: false,
    variables: [{
        label: "Vencimento",
        field: "dueDate",
        required: false,
    }, {
        label: "Número do título",
        field: "duplicate",
        required: false,
    }, {
        label: "Valor",
        field: "amount",
        required: true,
    }, {
        label: "Link do boleto",
        field: "invoiceUrl",
        required: true,
    }],
    batchHeaders: [{ label: "Operação", field: "operacao", required: true },
    { label: "Nome do contato", field: "nome_contato", required: true },
    { label: "Telefone", field: "telefone", required: true },
    { label: "Vencimento", field: "vencimento", required: false },
    { label: "Número do título", field: "parcela", required: false },
    { label: "Valor", field: "valor", required: true },
    { label: "Documento", field: "documento", required: false },
    { label: "Link do boleto", field: "invoiceUrl", required: true },
    ],
    sendOption: ["immediate", "later"],
};

const NOTIFICATION_POST_URL = process.env.REACT_APP_COMMUNICATION_API_URL;

enum MediaType {
    WPP = "WPP",
    MAIL = "MAIL",
}

type CreationType = {
    type: "batch" | "single";
    pageTitle: string;
}

type SendOption = "immediate" | "later";
type Variable = {
    field: string;
    value: string;
}

type Form = {
    sendOption: SendOption;
    operation: string;
    contactName: string;
    phoneNumber: string;
    email: string;
    cc: string;
    bcc: string;
    variables: Variable[];
}

const BatchNotificationForm: React.FC = () => {
    const navigate = useNavigate();

    const creationtype = useSignal<CreationType>({
        type: "single",
        pageTitle: "Criar notificação",
    });

    const { scheduledType, targetDate } = useParams();

    const mediaType = scheduledType?.split("_")[0];
    let template = templateEmail;
    if (mediaType === MediaType.WPP) {
        template = templateWpp;
    }

    const scheduledDateType = useSignal<string>("fixed");
    const isLoadingForm = useSignal<boolean>(false);
    const errorFields = useSignal<ErrorField[]>([]);

    const form = useSignal<Form>({
        sendOption: "immediate",
        operation: "",
        contactName: "",
        phoneNumber: "",
        email: "",
        cc: "",
        bcc: "",
        variables: [],
    });

    useEffect(() => {
        const handleEvent = (event: CustomEvent) => {
            console.log(JSON.stringify(event.detail, null, 2));
        };

        form.value.variables = template.variables.map(variable => ({
            field: variable.field,
            value: "",
        }));

        window.addEventListener("CopyPasteTable:jsonGenerated", handleEvent as EventListener);
        return () => window.removeEventListener("CopyPasteTable:jsonGenerated", handleEvent as EventListener);
    }, []);

    const generateJson = () => {
        window.dispatchEvent(new Event("CopyPasteTable:generateJson"));
    };

    const clearData = () => {
        window.dispatchEvent(new Event("CopyPasteTable:clearData"));
    };

    const changeCreationType = (type: "batch" | "single") => {
        creationtype.value = {
            type,
            pageTitle: type === "batch" ? "Criar notificação em lote" : "Criar notificação",
        };
    };

    const submitForm = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        try {
            const response = await fetch(`${NOTIFICATION_POST_URL}/notifications`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    scheduledType: scheduledDateType.value,
                    targetDate: targetDate,
                    sendOption: form.value.sendOption,
                    mediaType,
                    operation: form.value.operation,
                    contactName: form.value.contactName,
                    phone: form.value.phoneNumber,
                    email: form.value.email,
                    cc: form.value.cc,
                    variables: form.value.variables.reduce((acc, variable) => {
                        acc[variable.field] = variable.value;
                        return acc;
                    }, {} as Record<string, string>),
                }),
            });

            if (response.ok) {
                toast.info("Notificação criada com sucesso!");
                navigate(-1);
            } else {
                const { message, errors, title } = await response.json();
                toast.error(message);
                errorFields.value = errors ? errors : [];
            }
        } catch (error) {
            toast.error("Ocorreu um erro com a rede. Tente novamente mais tarde.");
        }
        isLoadingForm.value = false;
    };

    const changeForm = (field: string, value: string) => {
        form.value = {
            ...form.value,
            [field]: value,
        };
        removeErrorField(errorFields.value, field);
    };

    const changeFormVariables = (field: string, value: string) => {
        const newVariables = form.value.variables.map(variable => {
            if (variable.field === field) {
                return { ...variable, value };
            }
            return variable;
        });
        form.value = { ...form.value, variables: newVariables };
    };

    return (
        <PageForm
            pageTitle={creationtype.value.pageTitle}
            apiVersion="1.0.0"
            isLoading={false}
            breadcrumb={[{ label: "Notificações do dia", url: "/communication/notification/0/100/contactFullName/ASC/" },
            { label: `${creationtype.value.pageTitle}`, url: "#" }]}
        >
            {{
                controlSlot: (
                    <div className="flex space-x-4">
                        <button
                            className="bg-zinc-600 text-white px-4 py-2 rounded"
                            onClick={() => changeCreationType(creationtype.value.type === "batch" ? "single" : "batch")}
                        >
                            {creationtype.value.type === "batch" ? "Formulário" : "Em lote"}
                        </button>
                        <button
                            className="bg-red-500 text-white px-4 py-2 rounded"
                            onClick={clearData}
                        >
                            Limpar Dados
                        </button>
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded"
                            onClick={generateJson}
                        >
                            Gerar JSON do conteúdo
                        </button>
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                            onClick={submitForm}
                        >
                            Gravar
                        </button>
                    </div>
                ),
                formSlot: (
                    <div className="container mx-auto p-4">
                        <div className="grid grid-cols-12 gap-4 mb-4">
                            <div className={`${creationtype.value.type === "batch" ? "col-span-5" : "col-span-6"}`}>
                                <TextEdit label="Tipo de notificação:" value={template.description} disabled />
                            </div>
                            {creationtype.value.type === "batch" && (
                                <div className="col-span-2">
                                    <SelectFilter
                                        id="scheduledDateType"
                                        label="Opção de agendamento:"
                                        value={scheduledDateType.value}
                                        options={[
                                            { value: "fixed", label: "Data fixa" },
                                            { value: "fromFile", label: "Data personalizada" },
                                        ]}
                                        onChange={(value) => scheduledDateType.value = value}
                                    />
                                </div>
                            )}

                            <div className={`${creationtype.value.type === "batch" ? "col-span-2" : "col-span-3"}`}>
                                <TextEdit label="Data do agendamento:"
                                    className={`${scheduledDateType.value === "fromFile" && creationtype.value.type === "batch" ? "line-through text-gray-200" : ""}`}
                                    value={targetDate?.split("-").reverse().join("/")} disabled />
                            </div>

                            <div className="row-span-2 col-span-3 p-4 bg-white rounded-lg shadow-md border border-gray-200">
                                {mediaType === MediaType.WPP && (
                                    <FaWhatsapp className="col-auto text-3xl text-green-700 float-right mt-2" />
                                )}
                                {mediaType === MediaType.MAIL && (
                                    <FaMailBulk className="col-auto text-3xl text-blue-700 float-right mt-2" />
                                )}

                                <label className="flex items-center space-x-2 cursor-pointer">
                                    <input
                                        type="radio"
                                        name="sendOption"
                                        value="immediate"
                                        checked={form.value.sendOption === "immediate"}
                                        onChange={() => changeForm("sendOption", "immediate")}
                                        className="hidden"
                                    />
                                    <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
            ${form.value.sendOption === "immediate" ? "border-blue-500" : "border-gray-400"}`}>
                                        {form.value.sendOption === "immediate" && <div className="w-2.5 h-2.5 bg-blue-500 rounded-full"></div>}
                                    </div>
                                    <span className="text-gray-900 text-sm font-medium">Enviar imediatamente</span>
                                </label>

                                <label className="flex items-center space-x-2 cursor-pointer mt-1">
                                    <input
                                        type="radio"
                                        name="sendOption"
                                        value="later"
                                        checked={form.value.sendOption === "later"}
                                        onChange={() => changeForm("sendOption", "later")}
                                        className="hidden"
                                    />
                                    <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center 
            ${form.value.sendOption === "later" ? "border-blue-500" : "border-gray-400"}`}>
                                        {form.value.sendOption === "later" && <div className="w-2.5 h-2.5 bg-blue-500 rounded-full"></div>}
                                    </div>
                                    <span className="text-gray-900 text-sm font-medium">Gravar para enviar depois</span>
                                </label>
                            </div>

                            {mediaType === MediaType.MAIL && (
                                <>
                                    <div className="col-span-4">
                                        <TextEdit label="Titulo da mensagem:" value={templateEmail.subject} disabled />
                                    </div>
                                    <div className="col-span-5 grid grid-cols-2 gap-4">
                                        <div className="col-span-1">
                                            <TextEdit label="De:" value={templateEmail.from} disabled />
                                        </div>
                                        <div className="col-span-1">
                                            <TextEdit label="Responder para:" value={templateEmail.replyTo} disabled />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {creationtype.value.type === "batch" ? (
                            <CopyPasteTable fixedHeaders={template.batchHeaders} />
                        ) : (
                            <>
                                <div className="grid grid-cols-12 gap-4 mt-4 pt-4">

                                    <div className="col-span-2">
                                        <TextEdit label="Número da operação:" value={form.value.operation}
                                            placeholder=""
                                            onChange={(e) => changeForm("operation", e.target.value)}
                                            errorMessage={getErrorMessage(errorFields.value, "operation")}
                                            maxLength={25} />
                                    </div>


                                    <div className="col-span-7">
                                        <TextEdit label="Nome do contato:" value={form.value.contactName}
                                            placeholder=""
                                            onChange={(e) => changeForm("contactName", e.target.value)}
                                            errorMessage={getErrorMessage(errorFields.value, "contactName")}
                                            maxLength={250} />
                                    </div>

                                    {mediaType === MediaType.WPP && (
                                        <div className="col-span-3">
                                            <TextEdit label="Telefone:" value={form.value.phoneNumber}
                                                placeholder=""
                                                onChange={(e) => changeForm("phoneNumber", e.target.value)}
                                                errorMessage={getErrorMessage(errorFields.value, "phoneNumber")}
                                                maxLength={15}
                                            />
                                        </div>
                                    )}
                                    {mediaType === MediaType.MAIL && (
                                        <>
                                            <div className="col-start-1 col-span-4">
                                                <TextEdit label="Email:" value={form.value.email}
                                                    placeholder=""
                                                    onChange={(e) => changeForm("email", e.target.value)}
                                                    errorMessage={getErrorMessage(errorFields.value, "email")}
                                                    maxLength={200} />
                                            </div>

                                            {templateEmail.showCcField && (
                                                <div className="col-span-4">
                                                    <TextEdit label="Cc:" value={form.value.cc}
                                                        placeholder=""
                                                        onChange={(e) => changeForm("cc", e.target.value)}
                                                        errorMessage={getErrorMessage(errorFields.value, "cc")}
                                                        maxLength={200}
                                                    />
                                                </div>
                                            )}
                                            {templateEmail.showBccField && (
                                                <div className="col-span-4">
                                                    <TextEdit label="Bcc:" value={form.value.bcc}
                                                        placeholder=""
                                                        onChange={(e) => changeForm("bcc", e.target.value)}
                                                        errorMessage={getErrorMessage(errorFields.value, "bcc")}
                                                        maxLength={200}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    {template.variables.map(variable => (
                                        <div className="col-start-1 col-span-5">
                                            <TextEdit label={`${variable.label}:`} value={form.value.variables.find(v => v.field === variable.field)?.value}
                                                onChange={(e) => changeFormVariables(variable.field, e.target.value)}
                                                errorMessage={hasErrorMessage(errorFields.value, variable.field) ? getErrorMessage(errorFields.value, variable.field) : ""}
                                                placeholder=""
                                            />
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>),
            }}
        </PageForm>
    );
};

export default BatchNotificationForm;